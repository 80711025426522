import Blog from '../components/Blog';
import MarkdownContent from '../components/MarkdownContent';
import Page from '../components/Page';
import PropTypes from 'prop-types';
import React from 'react';
import { graphql } from 'gatsby'
import readNodes from '../utils/readNodes';
import styled from 'styled-components';

const BlogPage = styled(Page)`
  margin-bottom: 3rem;
`;

const asBlogPosts = blogPosts => readNodes(blogPosts).map(node => ({
  htmlAst: node.htmlAst,
  title: node.frontmatter.title,
  date: node.frontmatter.date,
}));

const IndexPage = ({
  data: {
    blogPostNodes,
    blogIntro: {
      htmlAst: blogIntroAst,
    },
    footer: {
      htmlAst: footerAst,
    },
  },
}) => (
  <BlogPage footerAst={footerAst}>
    <MarkdownContent contentAst={blogIntroAst} />
    <Blog posts={asBlogPosts(blogPostNodes)} />
  </BlogPage>
);

IndexPage.propTypes = {
  data: PropTypes.shape({
    blogIntro: PropTypes.shape({
      htmlAst: PropTypes.object.isRequired,
    }).isRequired,
    blogPostNodes: PropTypes.object.isRequired,
    footer: PropTypes.shape({
      htmlAst: PropTypes.object.isRequired,
    }).isRequired,
  }).isRequired,
};

export const pageQuery = graphql`
  query blog {
    blogIntro: markdownRemark(frontmatter: { type: { eq: "blog-intro" }}) {
      htmlAst
    }

    blogPostNodes: allMarkdownRemark(
      filter: { frontmatter: { type: { eq: "blog" }}}
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          htmlAst
          frontmatter {
            date(formatString: "DD.MM.YYYY")
            title
          }
        }
      }
    }

    footer: markdownRemark(frontmatter: { type: { eq: "footer" }}) {
      htmlAst
    }
  }
`;

export default IndexPage;
