import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import BlogPost, { BlogPostShape } from './BlogPost';

const BlogLayout = styled.section`
  margin-top: 1.5rem;
`;

const Blog = ({ posts }) => (
  <BlogLayout>
    { posts.map((post, i) => <BlogPost key={post.title} isEven={i % 2 === 0} {...post} />)}
  </BlogLayout>
);

Blog.propTypes = {
  posts: PropTypes.arrayOf(BlogPostShape).isRequired,
};

export default Blog;
