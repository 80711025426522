import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import MarkdownContent from '../../MarkdownContent';

import './BlogPost.scss';

const CSS_NS = 'blogPost';

const BlogPost = ({
  title,
  date,
  htmlAst,
  className,
  isEven,
}) => (
  <article className={cx(CSS_NS, className)}>
    <div className={`${CSS_NS}__header`}>
      <h2 className={cx(`${CSS_NS}__headline`, isEven ? 'textGradientPrimary' : 'textGradientSecondary')}>{ title }</h2>
      <div className={`${CSS_NS}__date`}>{ date }</div>
    </div>
    <div className={`${CSS_NS}__body`}>
      <MarkdownContent contentAst={htmlAst} className={`${CSS_NS}__bodyContent`} />
    </div>
  </article>
);

BlogPost.propTypes = {
  title: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  htmlAst: PropTypes.object.isRequired,
  className: PropTypes.any,
  isEven: PropTypes.bool,
};

BlogPost.defaultProps = {
  className: '',
  isEven: false,
};

export default BlogPost;
